import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';

import logo from 'img/help/integrations/zendesk-logo.svg';

const Content = () => {
  return (
    <div>
      <article>
        <SubTitle>What is Zendesk?</SubTitle>
        <p>
          Zendesk is a comprehensive customer service solution. It offers a wide range of features,
          including a customer support suite, a knowledge base tool, and a live chat module.
        </p>
      </article>

      <article>
        <SubTitle className="mt-4">How does this integration work?</SubTitle>
        <p>
          LiveSession Zendesk integration lets you see the most recent session replays of a user who
          submitted a ticket.
          <br />
          <br />
          <div className="alert alert-primary mb-5" role="alert">
            Before you begin, you’ll need a LiveSession{' '}
            <a
              href={`${process.env.GATSBY_LS_DEVELOPERS_URL}/rest-api/introduction/#creating-a-personal-access-token`}
              target="_blank" rel="noreferrer"
            >
              Personal Access Token
            </a>{' '}
            . Copy the token to your clipboard and then paste it into Zendesk. You will also have to
            set up{' '}
            <a href="/help/user-identification-custom-properties" target="_blank">
              user identification
            </a>
            .
          </div>
        </p>
      </article>

      <article>
        <SubTitle className="mt-4">How can I install this integration?</SubTitle>
        <ol>
          <li>
            Find the LiveSession app in the Zendesk{' '}
            <a href="https://www.zendesk.com/apps/directory" target="_blank" rel="noreferrer">
              {' '}
              marketplace
            </a>
            .
          </li>
          <li>Click the &quot;Install&quot; button to add our app to your Zendesk account.</li>
          <li>
            Update LiveSession’s &quot;Personal Access Token&quot; in the{' '}
            <a
              href="https://support.zendesk.com/hc/en-us/articles/203662486-Managing-your-installed-apps"
              target="_blank"
              rel="noreferrer"
            >
              manage settings
            </a>{' '}
            tab.
          </li>
          <li>Click &quot;Update&quot; to apply changes.</li>
          <li>
            Be sure that you use the{' '}
            <a href="/help/user-identification-custom-properties" target="_blank">
              identify
            </a>{' '}
            API along with the email property.
          </li>
        </ol>
      </article>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Zendesk integration',
  metaDescription: 'Send LiveSession recordings to your Zendesk account',
  canonical: '/help/zendesk-integration/',
  externalInstallLink: 'https://www.zendesk.com/apps/support/livesession',
  logoStyle: { width: 150 },
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
